<template>
  <div>
    <Navbar />
    <div>
      <div class="columns">
        <div class="column">
          <p class="text-subtitle bold">
            Issuances for {{ this.$route.query.userName }}
          </p>
          <b-input
            class="mt-4"
            placeholder="Search Book by Accession No..."
            type="search"
            v-model="searchQuery"
            @input="filterItems"
          ></b-input>
          <b-table
            class="mt-5"
            :data="filteredIssuances"
            :loading="isLoading"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            paginated
          >
            <b-table-column
              field="accessionNo"
              label="Accession No."
              sortable
              v-slot="props"
            >
              {{ props.row.printedBook.accessionNo }}
            </b-table-column>
            <b-table-column
              field="title"
              label="Book Title"
              sortable
              v-slot="props"
            >
              {{ props.row.printedBook.title }}
            </b-table-column>
            <b-table-column
              field="authors"
              label="Authors"
              sortable
              v-slot="props"
            >
              {{ props.row.printedBook.authors.join(", ") }}
            </b-table-column>
            <b-table-column
              field="issueDate"
              label="Issued On"
              sortable
              v-slot="props"
            >
              {{ props.row.issueDate | date }}
            </b-table-column>
            <b-table-column
              field="dueDate"
              label="Due Date"
              sortable
              v-slot="props"
            >
              {{ props.row.dueDate | date }}
            </b-table-column>
            <b-table-column
              field="returnDate"
              label="Date Returned"
              sortable
              v-slot="props"
            >
              <span v-if="props.row.returnDate">
                {{ props.row.returnDate | date }}
              </span>
              <span v-else class="has-text-danger">
                To be Returned
              </span>
            </b-table-column>
            <b-table-column label="Overdue Days" sortable v-slot="props">
              <span v-if="!props.row.returnDate">
                {{ props.row.dueDate | daysDuration(new Date()) }} days
              </span>
              <span v-else>-</span>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import api from "@/services/dataService";
import { filter } from "lodash";

export default {
  name: "UserIssuances",
  data() {
    return {
      issuances: undefined,
      filteredIssuances: undefined,
      isLoading: false,
      searchQuery: ""
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },
  async created() {
    await this.fetchIssuances();
  },
  methods: {
    async fetchIssuances() {
      const userId = this.$route.query.userId;
      this.isLoading = true;
      await api.get("/issuance/history/" + userId).then(res => {
        this.issuances = res.data;
        this.filterItems();
      });
      this.isLoading = false;
    },
    filterItems() {
      if (this.searchQuery === "") {
        this.filteredIssuances = this.issuances;
      } else {
        this.filteredIssuances = filter(this.issuances, item => {
          return item.printedBook["accessionNo"]
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
