<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p v-if="editMode" class="title">Edit User</p>
        <p v-else class="title">Add User</p>
      </div>
    </div>
    <hr />
    <form @submit.prevent="submitForm" id="editorForm">
      <div class="columns">
        <div class="column">
          <div class="box">
            <div class="columns">
              <div class="column">
                <b-field label="Acount Type*">
                  <b-select
                    v-model="formData.role"
                    placeholder="Select a role"
                    required
                    expanded
                  >
                    <option
                      v-for="userRole in userRoles"
                      :key="userRole.key"
                      :value="userRole.key"
                      >{{ userRole.value }}</option
                    >

                    <!-- <option value="ipri">IPRI</option>
                    <option value="free">Free</option>
                    <option value="paid">Paid</option>
                    <option value="pro">Pro</option> -->
                  </b-select>
                </b-field>
                <b-field v-if="editMode" label="Member Id">
                  <b-input disabled v-model="formData.memberId"></b-input>
                </b-field>
                <b-field label="First Name*">
                  <b-input v-model="formData.firstName" required></b-input>
                </b-field>
                <b-field label="Last Name*">
                  <b-input v-model="formData.lastName" required></b-input>
                </b-field>
                <b-field label="Contact*">
                  <b-input v-model="formData.contact" required></b-input>
                </b-field>
                <b-field label="Email*">
                  <b-input v-model="formData.email" required></b-input>
                </b-field>
                <b-field v-if="!editMode" label="Password*">
                  <b-input
                    type="password"
                    autocomplete="new-password"
                    v-model="formData.password"
                    required
                  ></b-input
                ></b-field>
              </div>
              <div class="column">
                <b-field label="NIC Number">
                  <b-input v-model="formData.nicNumber"></b-input>
                </b-field>
                <b-field label="Address">
                  <b-input v-model="formData.address"></b-input>
                </b-field>
                <b-field label="Department">
                  <b-input v-model="formData.department"></b-input>
                </b-field>
                <b-field v-if="false" label="Title">
                  <b-input v-model="formData.title"></b-input>
                </b-field>
              </div>
            </div>
            <hr />
            <div class="has-text-right">
              <b-button @click="cancel" class="button">Cancel</b-button>
              <button
                form="editorForm"
                class="button is-primary ml-4"
                type="submit"
                :disabled="isSubmitting"
              >
                {{ isSubmitting ? "Saving" : "Save" }}
              </button>
            </div>
          </div>
        </div>
        <div class="column is-4 is-narrow">
          <div v-if="editMode" class="box">
            <b-field
              label="Set new Password"
              message="Note: This will override user's current password"
            >
              <b-input v-model="formData.password"></b-input>
            </b-field>
          </div>
          <div v-if="formData.profilePicture" class="box">
            <p class="pb-3 bold has-text-centered">
              <i class="mdi mdi-file-outline pr-1"></i>
              Current Upload
            </p>
            <hr class="my-1" />
            <div v-if="formData.profilePicture" class="mt-2">
              <div class="bold">Cover Photo</div>
              <div class="columns mt-1">
                <div class="column is-narrow pt-1">
                  <img :src="formData.profilePicture" style="height: 100px" />
                </div>
                <div class="column has-text-right pt-1">
                  <a @click="deleteResource('cover')">
                    <b-tag type="is-danger is-light">
                      <i class="mdi mdi-close pr-1"></i> Delete
                    </b-tag>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!formData.profilePicture" class="box">
            <b-field label="Cover Photo">
              <section>
                <b-field>
                  <b-upload v-model="profilePictureFile" multiple drag-drop>
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"> </b-icon>
                        </p>
                        <p>Drop your files here or click to upload</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>

                <div class="tags">
                  <span
                    v-for="(file, index) in profilePictureFile"
                    :key="index"
                    class="tag is-primary"
                  >
                    {{ file.name }}
                    <button
                      class="delete is-small"
                      type="button"
                      @click="deleteDropFile(index)"
                    ></button>
                  </span>
                </div>
              </section>
            </b-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  name: "Editor",
  data() {
    return {
      isSubmitting: false,
      editMode: false,

      formData: {
        memberId: undefined,
        role: "ipri",
        userName: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        nicNumber: undefined,
        address: undefined,
        contact: undefined,
        department: undefined,
        title: undefined,
        active: undefined,
        password: undefined,
        profilePicture: undefined
      },
      profilePictureFile: [],
      userRoles: [
        { key: "admin", value: "Admin User" },
        { key: "ipri", value: "IPRI Staff" },
        { key: "free", value: "Free User" },
        { key: "paid", value: "Paid User" }
        // { key: "pro", value: "Pro" }
      ]
    };
  },
  async created() {
    if (this.$route.params.itemDetail) {
      this.editMode = true;
      for (var key in this.$route.params.itemDetail) {
        if (this.$route.params.itemDetail[key]) {
          this.formData[key] = Array.isArray(this.$route.params.itemDetail[key])
            ? this.$route.params.itemDetail[key].join(", ")
            : this.$route.params.itemDetail[key];
        }
      }

      if (
        this.formData.profilePicture ==
        "https://readersloungecovers.s3.us-east-2.amazonaws.com/User/default-cover.png"
      )
        this.formData.profilePicture = undefined;
    }
  },
  computed: {},
  methods: {
    deleteDropFile(index) {
      this.profilePictureFile.splice(index, 1);
    },
    cancel() {
      this.$router.push({ name: "Users" });
    },

    async deleteResource(type) {
      const deleteResourceRequest = {
        resourceId: this.$route.params.itemDetail._id,
        resourceType: "User",
        type: type
      };
      await this.$store
        .dispatch("resourceDelete", deleteResourceRequest)
        .then(() => {
          if (type == "cover") this.formData.profilePicture = undefined;
        });
    },

    async submitForm() {
      this.isSubmitting = true;
      const request = cloneDeep(this.formData);
      if (this.editMode) {
        const uploadRequest = new FormData();
        uploadRequest.append("coverPhoto", this.profilePictureFile[0]);
        uploadRequest.append("resourceId", this.$route.params.itemDetail._id);
        uploadRequest.append("resourceType", "User");
        if (this.profilePictureFile[0]) {
          await this.$store.dispatch("coverUpload", uploadRequest);
        }
        if (
          await this.$store.dispatch("editUser", {
            id: this.$route.params.itemDetail._id,
            request: request
          })
        ) {
          this.$router.push({ name: "Users" });
        }
      } else {
        const userObj = await this.$store.dispatch("addUser", request);
        if (userObj) {
          const uploadRequest = new FormData();
          uploadRequest.append("coverPhoto", this.profilePictureFile[0]);
          uploadRequest.append("resourceId", userObj.user.userId);
          uploadRequest.append("resourceType", "User");
          if (this.profilePictureFile[0]) {
            await this.$store.dispatch("coverUpload", uploadRequest);
          }

          this.$router.push({ name: "Users" });
        }
      }
      this.isSubmitting = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
