<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p class="title">Users</p>
      </div>
      <div class="column is-narrow">
        <b-button icon-left="plus" class="is-primary" @click="addItem"
          >Add User</b-button
        >
      </div>
    </div>
    <div class="columns is-vcentered">
      <div class="column is-3">
        <b-field>
          <b-input
            placeholder="Search..."
            type="search"
            v-model="searchQuery"
            @input="filterItems"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field>
          <div class="columns is-vcentered">
            <div class="column is-narrow bold">Search by:</div>
            <div class="column">
              <b-radio
                class="mt-1 pl-2"
                v-for="(columnData, index) in tableProps.columnData"
                :key="index"
                v-model="searchType"
                name="searchType"
                :native-value="columnData"
                @input="filterItems"
              >
                {{ tableProps.columnTitle[index] }}
              </b-radio>
            </div>
          </div>
        </b-field>
      </div>
      <div class="column is-narrow">
        <b-field label="Go to page:">
          <b-input v-model="tableProps.page" type="number" min="1"></b-input>
        </b-field>
      </div>
    </div>
    <hr />
    <b-table
      :data="filteredItems"
      :loading="tableProps.isLoading"
      :striped="tableProps.isStriped"
      :total="tableProps.total"
      :per-page="tableProps.perPage"
      :current-page="parseInt(tableProps.page)"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      paginated
    >
      <b-table-column
        v-for="(columnData, index) in tableProps.columnData"
        :key="index"
        :field="columnData"
        :label="tableProps.columnTitle[index]"
        sortable
        v-slot="props"
      >
        {{
          Array.isArray(props.row[columnData])
            ? props.row[columnData].join(", ")
            : props.row[columnData] | filter
        }}
      </b-table-column>
      <b-table-column field="actions" label="Actions" sortable v-slot="props">
        <span
          class="has-text-success pointer-active"
          @click="openViewDetailModal(props.row)"
        >
          <i class="mdi mdi-eye pr-1"></i> View
        </span>
        <span
          class="has-text-info pointer-active ml-4"
          @click="editItem(props.row)"
        >
          <i class="mdi mdi-pencil pr-1"></i> Edit
        </span>
        <span
          class="has-text-danger pointer-active ml-4"
          @click="openDeleteItemModal(props.row)"
        >
          <i class="mdi mdi-delete pr-1"></i> Delete
        </span>
        <span
          v-if="props.row.role == 'ipri'"
          class="has-text-info pointer-active ml-4"
        >
          <router-link
            :to="{
              name: 'UserIssuances',
              query: {
                userId: props.row._id,
                userName: props.row.firstName + ' ' + props.row.lastName
              }
            }"
          >
            <i class="mdi mdi-book pr-1"></i> View Issuances
          </router-link>
        </span>
      </b-table-column>
    </b-table>
    <!-- Modals Start -->
    <b-modal v-model="modals.showViewDetailModal" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">User Details</p>
        </header>
        <section v-if="itemDetail" class="modal-card-body">
          <div class="columns">
            <div class="column">
              <b-field
                v-for="(item, index) in modals.viewModalTitlesData"
                :key="index"
                :label="modals.viewModalTitles[index]"
              >
                {{
                  Array.isArray(itemDetail[item])
                    ? itemDetail[item].join(", ")
                    : itemDetail[item] | filter
                }}
              </b-field>
            </div>
            <div class="column is-6 has-text-centered mr-4">
              <img
                v-if="itemDetail.profilePicture"
                :src="itemDetail.profilePicture"
              />
              <div v-else class="my-5">
                <i class="mdi mdi-information-outline pr-1"></i> No cover
                uploaded
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button label="Close" @click="closeViewDetailModal" />
        </footer>
      </div>
    </b-modal>
    <b-modal v-model="modals.showDeleteItemModal" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete User</p>
        </header>
        <section v-if="itemDetail" class="modal-card-body">
          <b-message type="is-danger">
            <div class="columns is-vcentered">
              <div class="column">
                Are you sure you want to delete this user?
              </div>
              <div class="column is-narrow">
                <b-button label="Cancel" @click="closeDeleteItemModal" />
                <b-button
                  label="Confirm"
                  @click="deleteItem(itemDetail._id)"
                  type="is-danger"
                  class="ml-2"
                />
              </div>
            </div>
          </b-message>
          <div class="columns">
            <div class="column">
              <b-field
                v-for="(item, index) in modals.viewModalTitlesData"
                :key="index"
                :label="modals.viewModalTitles[index]"
              >
                {{
                  Array.isArray(itemDetail[item])
                    ? itemDetail[item].join(", ")
                    : itemDetail[item] | filter
                }}
              </b-field>
            </div>
            <div class="column has-text-right mr-4">
              <img :src="itemDetail.profilePicture" />
            </div>
          </div>
        </section>
        <footer class="modal-card-foot"></footer>
      </div>
    </b-modal>
    <!-- Modals End -->
  </div>
</template>

<script>
import { orderBy, filter } from "lodash";

export default {
  name: "Viewer",
  data() {
    return {
      filteredItems: undefined,
      searchQuery: undefined,
      searchType: "memberId",
      tableProps: {
        columnTitle: [
          "Member Id",
          "First Name",
          "Last Name",
          "E-mail",
          "Role",
          "Created At"
        ],
        columnData: [
          "memberId",
          "firstName",
          "lastName",
          "email",
          "role",
          "dateCreated"
        ],
        total: 0,
        isLoading: false,
        isStriped: true,
        sortOrder: "desc",
        defaultSortOrder: "desc",
        page: 1,
        perPage: 15
      },
      modals: {
        showViewDetailModal: false,
        showDeleteItemModal: false,
        viewModalTitles: [
          "Member Id",
          "First Name",
          "Last Name",
          "Email",
          "Role",
          "NIC Number",
          "Address",
          "Contact",
          "Department",
          "Title",
          "Active",
          "Date Added"
        ],
        viewModalTitlesData: [
          "memberId",
          "firstName",
          "lastName",
          "email",
          "role",
          "nicNumber",
          "address",
          "contact",
          "department",
          "title",
          "active",
          "dateCreated"
        ]
      },
      itemDetail: undefined
    };
  },
  async created() {
    this.tableProps.isLoading = true;
    await this.$store.dispatch("getUsers");
    this.filteredItems = this.items;
    this.tableProps.isLoading = false;
  },
  computed: {
    items() {
      return orderBy(this.$store.state.users, ["dateCreated"], ["desc"]);
    }
  },
  watch: {
    items: function(val) {
      this.filteredItems = val;
    }
  },
  methods: {
    openViewDetailModal(selectedItem) {
      this.itemDetail = selectedItem;
      this.modals.showViewDetailModal = true;
    },
    closeViewDetailModal() {
      this.modals.showViewDetailModal = false;
    },
    openDeleteItemModal(selectedItem) {
      this.itemDetail = selectedItem;
      this.modals.showDeleteItemModal = true;
    },
    closeDeleteItemModal() {
      this.modals.showDeleteItemModal = false;
    },
    async deleteItem(itemId) {
      const deleteResourceRequest = {
        resourceId: itemId,
        resourceType: "User",
        type: "cover"
      };
      await this.$store.dispatch("resourceDelete", deleteResourceRequest);
      await this.$store.dispatch("deleteUser", itemId);
      this.closeDeleteItemModal();
    },
    addItem() {
      this.$router.push({ name: "UsersEditor" });
    },
    editItem(item) {
      this.$router.push({ name: "UsersEditor", params: { itemDetail: item } });
    },
    filterItems() {
      if (this.searchQuery === "") {
        this.filteredItems = this.items;
      } else {
        this.filteredItems = filter(this.items, item => {
          return item[this.searchType]
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
